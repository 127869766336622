export const BFF_BASE_URI = '/api/service/navigation/v1/navigation/graphql'
export const DEFAULT_ADMIN_MENU_RESPONSE = {
  data: {
    adminMenu: [
      {
        title: 'Flow Control',
        link: '/flow-control',
        spaName: 'flow-control-spa'
      },
      {
        title: 'Scheduled Task Manager',
        link: '/scheduled-task-manager',
        spaName: 'scheduled-task-manager-spa'
      },
      {
        title: 'Service Elevations',
        link: '/service-elevations',
        spaName: 'service-elevations-spa'
      },
    ]
  }
}
