import React from 'react'
import { ToastLogo, ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import { useScreenSize, ScreenSize } from '@toasttab/use-screen-size'

const staticProps = {
  role: 'img',
  'aria-label': 'Toast Logo',
  fillColor: 'bg-gray-125',
  strokeColor: 'text-white'
}

export const ResponsiveBranding = ({ testId = 'branding' }) => {
  const screenSize = useScreenSize()

  if (screenSize <= ScreenSize.SM) {
    return (
      <ToastLogo
        {...staticProps}
        size='md'
        testId={testId}
        title='Toast Logo'
      />
    )
  }

  return (
    <ToastBrand
      {...staticProps}
      size='xs'
      testId={testId}
      title='Toast Brand'
      className='h-16 px-10 w-52'
    />
  )
}
